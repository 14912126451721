import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';

import { FORMAT_DATE_API, FORMAT_DATE_SHOW_TABLE } from '../utils/constants';
import { InvestmentPaymentsType } from '../utils/proptypes';
import { getNumberValueFromApi } from '../utils/utils';

type UseGeneratePaymentParams = {
  newPaymentReceivedDate: Date;
  investmentPayments: InvestmentPaymentsType[];
  interestOption: string;
};

type UseGeneratePaymentResponse = {
  receivedPayments: InvestmentPaymentsType[];
  receivedPaymentQuantity: number;
  initPaymentsSchedule: InvestmentPaymentsType[];
  amountReceived?: number;
  dueDate?: Date;
};
export const useGeneratePayment = ({
  newPaymentReceivedDate,
  investmentPayments,
  interestOption,
}: UseGeneratePaymentParams): UseGeneratePaymentResponse => {
  const [t] = useTranslation()
  const receivedPayments: InvestmentPaymentsType[] = useMemo(() => {
    return investmentPayments?.filter((payment: InvestmentPaymentsType) => payment.name && payment.amountReceived) as InvestmentPaymentsType[];
  }, [investmentPayments]);

  const receivedPaymentQuantity: number = useMemo(() => {
    return receivedPayments.length;
  }, [receivedPayments]);

  const initPaymentsSchedule = useMemo(() => {
    return investmentPayments?.filter((payment: InvestmentPaymentsType) => !payment.name);
  }, [investmentPayments]);

  const payPrincipal: number = useMemo(() => {
    return getNumberValueFromApi(initPaymentsSchedule?.[receivedPaymentQuantity + 1]?.principalReceived as number);
  }, [initPaymentsSchedule, receivedPaymentQuantity]);

  const interestPayPerDate: number = useMemo(() => {
    return getNumberValueFromApi(
      (initPaymentsSchedule?.[receivedPaymentQuantity + 1]?.interestReceived as number) /
      (initPaymentsSchedule?.[receivedPaymentQuantity + 1]?.transactionDays as number),
    );
  }, [initPaymentsSchedule, receivedPaymentQuantity]);

  const newDaysSinceLastTransaction: number = useMemo(() => {
    return newPaymentReceivedDate
      ? moment(newPaymentReceivedDate).diff(
        moment(moment.utc(initPaymentsSchedule?.[receivedPaymentQuantity + 1]?.dueDate).format(FORMAT_DATE_API)),
        t('days'),
      )
      : (initPaymentsSchedule?.[receivedPaymentQuantity + 1]?.transactionDays as number);
    // eslint-disable-next-line
  }, [initPaymentsSchedule, receivedPaymentQuantity]);

  const payInterest: number = useMemo(() => {
    return (
      getNumberValueFromApi(initPaymentsSchedule?.[receivedPaymentQuantity + 1]?.interestReceived as number) +
      (newPaymentReceivedDate ? interestPayPerDate * newDaysSinceLastTransaction : 0)
    );
    // eslint-disable-next-line
  }, [initPaymentsSchedule, receivedPaymentQuantity, interestPayPerDate, newDaysSinceLastTransaction]);

  const amountReceived = useMemo(() => {
    return interestOption !== 'Roll-Over'
      ? payInterest + payPrincipal
      : receivedPaymentQuantity === 0
        ? getNumberValueFromApi(initPaymentsSchedule?.[initPaymentsSchedule?.length - 1]?.amountReceived)
        : undefined;
    // eslint-disable-next-line
  }, [payInterest, payPrincipal, initPaymentsSchedule, interestOption, receivedPaymentQuantity]);

  const dueDate = useMemo(() => {
    const initPaymentsScheduleWithoutElement0 = initPaymentsSchedule?.filter((payment: InvestmentPaymentsType) => payment.amountReceived);

    return interestOption !== 'Roll-Over'
      ? initPaymentsScheduleWithoutElement0?.[receivedPaymentQuantity]?.dueDate
        ? moment(moment.utc(initPaymentsScheduleWithoutElement0?.[receivedPaymentQuantity]?.dueDate).format(FORMAT_DATE_SHOW_TABLE)).toDate()
        : undefined
      : receivedPaymentQuantity === 0
        ? moment(
          moment.utc(initPaymentsScheduleWithoutElement0?.[initPaymentsScheduleWithoutElement0?.length - 1]?.dueDate).format(FORMAT_DATE_SHOW_TABLE),
        ).toDate()
        : undefined;
  }, [initPaymentsSchedule, receivedPaymentQuantity, interestOption]);

  return {
    receivedPayments,
    initPaymentsSchedule,
    receivedPaymentQuantity,
    amountReceived,
    dueDate,
  };
};
