import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import NoResult from 'components/common/noResult';
import TooltipComponent from 'components/common/Tooltip';
import DateInput from 'components/input/dateInput/DateInput';
import moment from 'moment';
import {
  ABILITY_ACTION,
  FORMAT_MONTH_API,
  FORMAT_MONTH_PICKER,
  PAYMENT_STATUS,
  PERMISSION,
  ROUTE_PATHS,
} from 'utils/constants';
import { FilterUpcomingPaymentsValueType, InvestmentPaymentsType, UpcomingPaymentsType } from 'utils/proptypes';
import { getColorStatus } from 'utils/utils';

import CustomSpinner from '../../../../components/common/customSpinner';
import ability from '../../../../configs/casl/ability';

type UpcomingPaymentsProps = {
  upcomingPayments: UpcomingPaymentsType[];
  tbl: MutableRefObject<null>;
  refetchInvestmentPayments: Function;
  setFilterInvestmentPaymentsValue: Function;
  setIsOpenRecordPaymentConfirmModal: Function;
  setSelectedInvestmentPayment: Function;
  isFetching: boolean;
};

const RenderUpcomingPayments = (props: UpcomingPaymentsProps) => {
  const navigate = useNavigate();
  const { upcomingPayments, tbl, setFilterInvestmentPaymentsValue, setIsOpenRecordPaymentConfirmModal, setSelectedInvestmentPayment, isFetching } =
    props;
  const [t] = useTranslation()
  const onRecordPaymentClick = (payment: Partial<UpcomingPaymentsType & InvestmentPaymentsType>) => {
    setIsOpenRecordPaymentConfirmModal(true);
    setSelectedInvestmentPayment(payment);
  };

  const openInvestmentDetail = (item: UpcomingPaymentsType) => {
    navigate(`${ROUTE_PATHS.SUPPORTER_MANAGER_PAGE}/${item?.supporterId}${ROUTE_PATHS.INVESTMENT_PAGE}/${item?.investmentId}`);
  };

  return (
    <div className="upcomingPayments-table flex-1 h-full bg-white">
      <div className="bg-gray-100 border-b border-gray-200">
        <h4 className="text-gray-900 font-semibold whitespace-nowrap pt-3 px-3 uppercase">{t('upcomingPayments')}</h4>
        <div className="upcomingPayments-table__header flex justify-between items-center p-3 cursor-pointer">
          <DateInput
            placeHolder={t('dueDate')}
            dateFormat={FORMAT_MONTH_PICKER}
            isFilter={true}
            defaultValue={moment().format()}
            selectItemsHandler={(fPaymentDate: Date, setStartDate: Function) => {
              setStartDate(fPaymentDate);
              if (setFilterInvestmentPaymentsValue)
                setFilterInvestmentPaymentsValue((state: FilterUpcomingPaymentsValueType) => ({
                  ...state,
                  fPaymentDate: fPaymentDate ? moment(fPaymentDate).format(FORMAT_MONTH_API) : '',
                }));
            }}
            showMonthYearPicker={true}
          />
        </div>
      </div>
      <div className="upcomingPayments-table__body h-full overflow-y-auto whitespace-nowrap">
        {upcomingPayments?.length ? (
          <table ref={tbl} className="w-full text-sm text-left text-gray-500 dark:text-gray-400" data-testid="test-upcomingPayments-table">
            <tbody>
              {upcomingPayments?.map((item: UpcomingPaymentsType, key: number) => (
                <tr
                  key={`${key}-info-upcomingPayments`}
                  className={classNames(
                    `upcomingPayments-${key === upcomingPayments?.length - 1 ? 'last' : key
                    } border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600`,
                  )}
                  data-testid="test-upcomingPayments-row"
                  onClick={() => {
                    if (ability.can(ABILITY_ACTION.manage, PERMISSION.SUPPORTER.VIEW_DETAIL)) {
                      openInvestmentDetail(item);
                    }
                  }}
                >
                  <th
                    scope="row"
                    className="py-2 px-3 font-normal text-sm text-gray-900 upcomingPayments-page--truncate max-width-140 dark:text-white"
                  >
                    <div>
                      <TooltipComponent
                        anchorSelect={`upcomingPayments-supporterName-${key}`}
                        content={`${item.supporterName}`}
                        className="upcomingPayments-page--truncate font-semibold max-w-full dark:text-white"
                      />
                    </div>
                    <div className="mt-2 flex justify-start items-center">
                      <span>
                        <TooltipComponent
                          anchorSelect={`upcomingPayments-paymentCode-${key}`}
                          content={`${item.paymentCode}`}
                          className="upcomingPayments-page--truncate font-semibold max-w-full dark:text-white"
                        />
                      </span>
                      <span className="supporter-type capitalize">
                        <p className={`supporter-type__${item.paymentType} leading-none`}>{item.paymentType}</p>
                      </span>
                    </div>
                    <div className="mt-2">
                      <TooltipComponent
                        anchorSelect={`upcomingPayments-name3-${key}`}
                        content={item.status === PAYMENT_STATUS.UN_PAID ? `${t('due')} ${item.dueDate}` : `${t('received')} ${item.receivedDate}`}
                        className="font-normal upcomingPayments-page--truncate max-w-full dark:text-white"
                      />
                    </div>
                  </th>
                  <th scope="row" className="py-2 px-3 font-normal text-gray-900 upcomingPayments-page--truncate max-width-140 dark:text-white">
                    <div className="flex flex-col justify-between items-end" style={{ height: '76px' }}>
                      <div className="payment-amount text-red-500 uppercase">
                        <TooltipComponent
                          anchorSelect={`upcomingPayments-name4-${key}`}
                          content={`${item.amount}`}
                          className="font-semibold upcomingPayments-page--truncate max-w-full dark:text-white"
                        />
                      </div>
                      <button
                        className={`payment-button text-gray-900 uppercase text-xs leading-none font-normal px-3 ${getColorStatus(
                          item.status === PAYMENT_STATUS.UN_PAID ? PAYMENT_STATUS.PAID : PAYMENT_STATUS.UN_PAID,
                        )}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          onRecordPaymentClick({
                            investmentId: item.investmentId,
                            id: item.paymentId,
                            checkNumber: item.checkNumber ? item.checkNumber : '',
                            preferredPaymentMethod: item.preferredPaymentMethod ? item.preferredPaymentMethod : undefined,
                            amountReceived: item.amountReceived,
                            dueDate: item.dueDate,
                          })
                         }
                        }
                        disabled={item.status === PAYMENT_STATUS.PAID}
                      >
                        {item.status === PAYMENT_STATUS.UN_PAID ? t('recordPayment') : t(PAYMENT_STATUS.PAID)}
                      </button>
                    </div>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="h-full upcoming-payments-no-result text-center bg-white py-4 flex justify-center items-center">
            {isFetching ? <CustomSpinner /> : <NoResult />}
          </div>
        )}
      </div>
      <div />
    </div>
  );
};
export default RenderUpcomingPayments;
