import { MutableRefObject, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import classNames from 'classnames';

type CurrencyFormType = {
  isDisabled?: boolean;
  decimalLimit?: number;
  defaultValue?: number;
  changeValueHandler: Function;
  prefix?: string;
  suffix?: string;
  inputRef: MutableRefObject<any>;
  height?: string;
  width?: string;
  hasBlur?: boolean;
};

const CurrencyInput = ({
  defaultValue,
  changeValueHandler,
  isDisabled = false,
  decimalLimit = 0,
  prefix,
  suffix,
  inputRef,
  height,
  width = 'w-full',
  hasBlur = false,
}: CurrencyFormType) => {
  const [currentValue, setCurrentValue] = useState<number | string>('');
  useEffect(() => {
    return () => setCurrentValue(defaultValue ?? '');
  }, [defaultValue]);
  return (
    <div className={`currency-input flex flex-col ${width}`}>
      <div className={`${width} flex justify-center items-center`}>
        <div className="hidden this-value-for-export-csv">{defaultValue}</div>
        <NumericFormat
          getInputRef={inputRef}
          value={defaultValue ?? ''}
          onValueChange={values => {
            setCurrentValue(values.floatValue ? values.floatValue : values.formattedValue);
          }}
          onKeyUp={(e: any) => {
            if (e.key === 'Enter') {
                changeValueHandler(Number(e.target.value.replace(/[$,]/g, '')));
            }
          }}
          onBlur={(e) => {
            if(hasBlur) {
              const newValue = Number(e.target.value.replace(/[$,]/g, ''));
              if (newValue !== Number(defaultValue || 0)) {
                changeValueHandler(newValue, true);
              }
            }
          }}
          disabled={isDisabled}
          maxLength={15}
          thousandSeparator=","
          decimalScale={decimalLimit}
          fixedDecimalScale={true}
          prefix={prefix}
          suffix={suffix}
          className={classNames(`${width} ${height} text-sm text-gray-900 border border-gray-200 px-1.5 py-2 rounded`)}
        />
      </div>
    </div>
  );
};

export default CurrencyInput;
